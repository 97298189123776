import React from 'react'
import './text.scss'

const Text = (props: any) => {
  return (
    <div
      style={{
        fontSize: `${props.size ? props.size : 12}px`,
        lineHeight: `${props.height ? props.height : 16}px`,
        color: props.color ? props.color : "black",
        fontWeight: `${props.weight ? props.weight : 300}`,
      }}
      className="text__component">
      {props.text}
    </div>
  );
}

export default Text