import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyUserState } from ".";

const initialState: CompanyUserState = {
	companyUsers: [],
	companyUser: null,
	loading: false,
};

export const companyUserSlice = createSlice({
	name: "companyUser",
	initialState,
	reducers: {
		
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		// addCustomer: (state, action) => {
		// 	state.customers.push(action.payload);
		// },
		// updateCustomer: (state, action) => {
		// 	const { id, customer } = action.payload;

		// 	state.customers.every(({ _id }, i) => {
		// 		if (id === _id) {
		// 			state.customers[i] = customer;
		// 			return false;
		// 		}

		// 		return true;
		// 	});
		// },
		setCompanyUsers: (state, action) => {
			state.companyUsers = action.payload;
		},
		setSingleUser: (state, action) => {
			state.companyUser = action.payload;
		},
	},
});

export const companyUserReducer = companyUserSlice.reducer;

export const companyUserActions = companyUserSlice.actions;
