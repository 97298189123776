import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import EditMapAddress from "./EditMapAddress";

const HandleStatus = lazy(() => import("./HandleStatus"));
const UpdateBooking = lazy(() => import("./UpdateBooking"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  HANDLE_STATUS: <HandleStatus />,
  UPDATE_BOOKING: <UpdateBooking />,
  CHANGE_PASSWORD: <ChangePassword />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  EDIT_ADDRESS: <EditMapAddress />,
};
