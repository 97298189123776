import "./App.css";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";
import Header from "components/templates/Header";

import { createTheme } from "@mui/material";

import createCache from "@emotion/cache";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/lab";
import dateAdapter from "@mui/lab/AdapterDateFns";
// import { ScopedCssBaseline } from "@mui/material";
import AppModal from "components/templates/AppModal";
import { ThemeProvider } from "@mui/material/styles";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import RegisterAppDispatch from "components/atoms/RegisterAppDispatch";
import Footer from "components/templates/Footer";
import { useTranslation } from "react-i18next";
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
// import theme from "theme";


declare module "@mui/material/styles" {
	interface Theme {
		status: {
			success: object;
			warning: object;
		};
	}
	interface ThemeOptions {
		status?: {
			success?: object;
			warning?: object;
		};
	}
}






const cache = createCache({ key: "css", prepend: true });

const cacheRtl = createCache({
	key: 'muirtl',
	//@ts-ignore
	stylisPlugins: [prefixer, rtlPlugin],
 });
export default function App() {
	const {i18n} = useTranslation();

	const theme = createTheme({
		status: {
		  success: {
			color: "rgba(84, 214, 44, 0.16)",
			textContrast: "rgb(34, 154, 22)",
		  },
		  warning: {
			color: "rgba(255, 193, 7, 0.16)",
			textContrast: "rgb(183, 129, 3)",
		  },
		},
		palette: {
		  primary: {
			light: "#4b525a",
			main: "#520A76;",
			dark: "#520A76",
			// contrastText: "rgb(2, 136, 209)",
		  },
		  secondary: {
			light: "#fffadd",
			main: "#f5d312",
			dark: "#e7c400",
			// contrastText: "rgb(2, 136, 209)",
		  },
		  success: {
			main: "rgb(34, 154, 22)",
			light: "rgba(84, 214, 44, 0.16)",
			dark: "#1b5e20",
			// contrastText: "rgb(34, 154, 22)",
		  },
		  warning: {
			main: "rgb(183, 129, 3)",
			light: "rgba(255, 193, 7, 0.16)",
			dark: "#e65100",
			// contrastText: "rgb(183, 129, 3)"
		  },
		  error: {
			main: "rgb(183, 33, 54)",
			light: "rgba(255, 72, 66, 0.16)",
			dark: "#c62828",
			// contrastText: "rgb(183, 33, 54)",
		  },
		  info: {
			main: "rgb(2, 136, 209)",
			light: "rgb(2, 136, 209, 0.16)",
			dark: "#01579b",
			// contrastText: "rgb(2, 136, 209)",
		  },
		},
		typography: {
		//   fontFamily: '"Outfit", sans-serif',
			fontFamily: i18n.language ==="ar"?'arabic':'Outfit',

		},
	  });

	return (
		<ErrorBoundary>
			<SnackbarProvider
				maxSnack={5}
				hideIconVariant
				preventDuplicate
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				iconVariant={{
					success: "✅",
					error: "✖️",
					warning: "⚠️",
					info: "ℹ️",
				}}
			>
				<Toaster />
				<ErrorBoundary>
				<CacheProvider value={i18n.language ==="ar"?cacheRtl:cache}>
						<ThemeProvider theme={theme}>
							<LocalizationProvider dateAdapter={dateAdapter}>
								<CssBaseline />
								{/* <ScopedCssBaseline> */}
								<Provider store={store}>
									<RegisterAppDispatch />
									<BrowserRouter>
										<ErrorBoundary>
											<Header />
										</ErrorBoundary>
										<ErrorBoundary>
											<AppModal />
										</ErrorBoundary>
										<ErrorBoundary>
											<AppRoutes />
										</ErrorBoundary>
										<Footer/>
									</BrowserRouter>
								</Provider>
								{/* </ScopedCssBaseline> */}
							</LocalizationProvider>
						</ThemeProvider>
					</CacheProvider>
				</ErrorBoundary>
			</SnackbarProvider>
		</ErrorBoundary>
	);
}
