import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import EnTranslation from "./en/languages.json";
import ArTranslation from "./ar/languages.json";

const resources = {
  en: {
    translation: EnTranslation,
  },
  ar: {
    translation: ArTranslation,
  },
};

i18next.use(initReactI18next).init({
  resources,
  // lng: "ar",
  lng: localStorage.getItem("lang") ?? navigator.language,
  debug: true,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }

});
export default i18next;
