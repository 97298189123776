import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

import ErrorBoundary from "components/atoms/ErrorBoundary";
import InitializationService from "services/initialization.service";

import "./locales/config";


InitializationService.init();

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
