import { Grid } from "@mui/material";
import { change, Field, reduxForm } from "redux-form";
import { useEffect } from "react";

import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { modalActions } from "redux/slices/modal";
import { mapAction } from "redux/slices/map/mapSlice";
import { useTranslation } from "react-i18next";

function EditMapInputForm() {
  const bookingForm = "AddBookingForm";
  const form = "EditMapInputForm";
	const { t } = useTranslation("translation");
  const dispatch = useAppDispatch();
  const show = useAppSelector((state) => state.map.show)

  const pickup = useAppSelector(
    (state) => state.form?.[bookingForm]?.values?.pickup?.value
  );
  const destination = useAppSelector(
    (state) => state.form?.[bookingForm]?.values?.destination?.value
  );

  

  useEffect(() => {
		dispatch(change(form, "pickup", pickup));
		dispatch(change(form, "destination", destination));
  
},[destination, dispatch, pickup])
  

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={10}>
          {  show === 0 ?
            <Field
              name={"pickup"}
              label={t("pickUp")}
              validate={[required]}
              component={InputRedux}
              InputProps={{ disabled: true }}
            />
            :
            <Field
              name={"destination"}
              label={t("destination")}
              validate={[required]}
              component={InputRedux}
              InputProps={{ disabled: true }}
            />}
          </Grid>
          <Grid item xs={2}>
            
            <Button
              onClick={() => {
                
                dispatch(modalActions.closeModal())
              }}
              style={{ width: "100%", height: "100%" }}
              variant="contained">
              {t("mapSubmit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default reduxForm({ form: "EditMapInputForm" })(EditMapInputForm);
