import { CustomerState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: CustomerState = {
	customers: [],
	customer: null,
	loading: true,
	customerOptions: [],
};

export const customerSlice = createSlice({
	name: "customer",
	initialState,
	reducers: {
		clear: (state) => {
			state.customerOptions = [];
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addCustomer: (state, action) => {
			state.customers.push(action.payload);
		},
		updateCustomer: (state, action) => {
			const { id, customer } = action.payload;

			state.customers.every(({ _id }, i) => {
				if (id === _id) {
					state.customers[i] = customer;
					return false;
				}

				return true;
			});
		},
		setCustomer: (state, action) => {
			state.customer = action.payload;
		},
		setCustomers: (state, action) => {
			const customers = action.payload;
			let options: SelectOption[] = [];
			customers.forEach(({ _id, title, first_name, last_name }: any) =>
				options.push({
					value: _id,
					label: `${title} ${first_name} ${last_name}`,
				})
			);
			state.customers = customers;
			state.customerOptions = options;
		},
	},
});

const customerReducer = customerSlice.reducer;

export const customerActions = customerSlice.actions;
export default customerReducer;
