import "./Header.css";
import logo from "assets/long_logo.png";
import { Container, Grid, Stack } from "@mui/material";
import AuthService from "services/auth.service";
import useEffectOnce from "hooks/useEffectOnce";
import { useAppSelector } from "redux/hooks";
import BasicMenu from "components/atoms/BasicMenu/BasicMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FaAngleDown } from "react-icons/fa";
import { useCallback, useEffect } from "react";


interface ILink {
  to: string;
  text: string;
  active: string;
}

export default function Header() {
  const { t, i18n } = useTranslation("translation");



  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  // const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const changeLang = useCallback((lng: any) => {

    i18n.changeLanguage(lng);

  }, [i18n])

  const arabicLang = useCallback(() => {
    localStorage.setItem("lang", 'ar')

    changeLang('ar');

    document.body.style.direction = "rtl";
    document.body.style.fontFamily = "arabic";
  }, [changeLang])
  const englishLang = useCallback(
    () => {
      localStorage.setItem("lang", 'en')

      changeLang('en');
      document.body.style.direction = "ltr";
      document.body.style.fontFamily = "inter";

    }, [changeLang]
  )

  useEffect(() => {

    if (i18n.language === 'ar') {
      arabicLang()
    } else {
      englishLang()
    }



  }, [arabicLang, englishLang, i18n.language])

  useEffectOnce(() => {
    if (!user) return;
  });
  const links: ILink[] = [
    { to: "/bookings", text: t("bookings"), active: "booking" },
    { to: "/customers", text: t("customers"), active: "customer" },
    { to: "/invoices", text: t("invoices"), active: "invoice" },
  ];

  if (
    !user ||
    pathname === "/" ||
    pathname === "/password-otp" ||
    pathname === "/reset-password" ||
    pathname === "/forgot-password"
  )
    return <></>;

  return (
    <header>
      <div className="navbar">
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3}>
              <Link to="/bookings">
                <div
                  // className="logo"

                  className={i18n.language === "en" ? "logo" : "logo arLogo"}

                >
                  <img
                    src={logo}
                    alt="logo"
                    loading="lazy"
                    width={"170px"}
                    height={"100%"}
                  />
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} md={9}>
              <ul>
                {links.map(({ to, text, active }, i) => (
                  <li key={i}>
                    <Link
                      to={to}
                      className={pathname.includes(active) ? "active" : ""}>
                      {text}
                    </Link>
                  </li>
                ))}
                <BasicMenu
                  list={[
                    {
                      text: "EN",
                      onClick: englishLang,
                    },
                    {
                      text: "عربي",
                      onClick: arabicLang,
                    },
                  ]}

                  avatar={false}
                  color="#ffffff"
                >
                  <Stack style={{ marginLeft: "30px" }} direction="row" alignItems="center" columnGap={1}>
                    <span>{i18n.language === "en" ? "EN" : "عربي"}</span>

                    <FaAngleDown />
                  </Stack>
                </BasicMenu>
                <li>
                  <div style={{ padding: "10px 0" }}>
                    <BasicMenu
                      text={user.first_name.charAt() + user.last_name.charAt()}
                      list={[
                        {
                          text: user.first_name + " " + user.last_name,
                          onClick: () => navigate("/profile"),
                        },
                        {
                          text: t("account_access"),
                          onClick: () => navigate("/account-access")
                        },
                        {
                          text: t("logout"),
                          onClick: () => { AuthService.logout() },
                        },

                      ]}
                      avatar={true}

                    />
                  </div>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </div>
    </header>
  );
}
