export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  HANDLE_STATUS = "HANDLE_STATUS",
  UPDATE_BOOKING = "UPDATE_BOOKING",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  EDIT_ADDRESS = "EDIT_ADDRESS",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
