import Input from "components/atoms/Input";
import { useEffect, useState } from "react";
import  i18next  from 'i18next';
export default function InputRedux({
	input,
	handleBlur,
	handleFocus,
	meta: { error, touched, invalid },
	InputProps,
	...rest
}: any) {

	const [cError , setCError] = useState(i18next.t(`${error}`));
	const [currentLanguage,setCurrlang]=useState(i18next.language);

	

	if(currentLanguage !== i18next.language){
		setCurrlang(i18next.language)
	}
	useEffect(()=>{
		setCError(i18next.t(`${error}`));
	},[error,currentLanguage])

	return (
		<Input
			{...InputProps}
			{...rest}
			{...input}
			helperText={touched && invalid && cError}
			error={touched && invalid && error && true}
			onBlur={(e: any) => {
				handleBlur?.(e);
				e.preventDefault();
			}}
			onFocus={(e: any) => {
				handleFocus?.(e);
				e.preventDefault();
			}}
		/>
	);
}
