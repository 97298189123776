import { InvoiceState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: InvoiceState = {
  invoices: [],
  invoice: null,
  loading: true,
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addInvoice: (state, action) => {
      state.invoices.push(action.payload);
    },
    updateInvoice: (state, action) => {
      const { id, invoice } = action.payload;

      state.invoices.every(({ _id }, i) => {
        if (id === _id) {
          state.invoices[i] = invoice;
          return false;
        }

        return true;
      });
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoices: (state, action) => {
      state.invoices = action.payload;
    },
  },
});

const invoiceReducer = invoiceSlice.reducer;

export const invoiceActions = invoiceSlice.actions;
export default invoiceReducer;
