import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { mapAction } from "redux/slices/map/mapSlice";
import MapService from "services/map.service";
import EditMapInputForm from "../EditMapInputs/EditMapInputForm";





const GoogleMapComponent = (props: { google: any; }) => {
  const dispatch = useAppDispatch();
  const form = "AddBookingForm";

  const picklatlong = useAppSelector(
    (state) => state.form?.[form]?.values?.pickup?.details?.geometry?.location
  );
  const destinationlatlong = useAppSelector(
    (state) =>
      state.form?.[form]?.values?.destination?.details?.geometry?.location
  );

  let startLat = useAppSelector((state) => state.map.startLat);
  let startlng = useAppSelector((state) => state.map.startlng);

  let endLat = useAppSelector((state) => state.map.endLat);
  let endlng = useAppSelector((state) => state.map.endlng);

  const show = useAppSelector((state)=> state.map.show)

  const formattedStartlat = useAppSelector(
     // @ts-ignore
     (state) => state?.map?.startLocation?.lat);
     const formattedStartlng = useAppSelector(
     // @ts-ignore
     (state) => state?.map?.startLocation?.lng
   );

   const formattedEndlat = useAppSelector(
     // @ts-ignore
     (state) => state?.map?.endLocation?.lat
   );
   const formattedEndlng = useAppSelector(
     // @ts-ignore
     (state) => state?.map?.endLocation?.lng
   );


  // eslint-disable-next-line react-hooks/exhaustive-deps
  let startLocation = { lat: "", lng: "" };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let endLocation = { lat: "", lng: "" };

  useEffect(() => {
    dispatch(mapAction.setStartLocation(startLocation));
    dispatch(mapAction.setEndLocation(endLocation));
  }, [dispatch, endLocation, startLocation]);

  // pickup geocoding api
  let pick;
  useEffect(() => {

    if (
      formattedStartlat === startLat ||
      formattedStartlng === startlng ||
      formattedStartlat === undefined ||
      formattedStartlng === undefined
    )
      return;
    MapService.getLocation(formattedStartlat, formattedStartlng, (pick = true));
  }, [formattedStartlat, formattedStartlng, pick, startLat, startlng]);

  // destination geocoding api
  useEffect(() => {
    if (formattedEndlat !== endLat) {
      
    }
    if (formattedEndlat === endLat || formattedEndlng === endlng ||
     formattedEndlat === undefined ||
      formattedEndlng === undefined
    ) return;
    MapService.getLocation(formattedEndlat, formattedEndlng, (pick = false));
  }, [endLat, endlng, formattedEndlat, formattedEndlng]);

  let markersList = [
    show === 0 && {
      lat: picklatlong?.lat ? picklatlong?.lat : startLat,
      lng: picklatlong?.lng ? picklatlong?.lng : startlng,
    },
    // hourly !== true
    show === 1 && {
      lat: destinationlatlong?.lat ? destinationlatlong?.lat : endLat,
      lng: destinationlatlong?.lng ? destinationlatlong?.lng : endlng,
    },
  ];
  let [markers, setMarkers] = useState(markersList);

  let [data, setData] = useState();


  let onMarkerDragEnd = (
    coord: { latLng: any },
    index: string,
    markers: any
  ) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    markers[index] = { lat, lng };

    setData(markers[index]);
    setMarkers(markers);
  };

  let myMarkers =
    markers &&
    Object.entries(markers).map(([key, val]) => (
      <Marker
        key={key}
        // @ts-ignore
        id={key}
        position={{
          // @ts-ignore
          lat: val?.lat,
          // @ts-ignore
          lng: val?.lng,
        }}
        draggable={true}
        onDragend={(t: any, map: any, coord: any) =>
          onMarkerDragEnd(coord, key, markers)
        }
      />
    ));

  startLocation.lat = myMarkers[0]?.props?.position?.lat;
  startLocation.lng = myMarkers[0]?.props?.position?.lng;
  endLocation.lat = myMarkers[1]?.props?.position?.lat;
  endLocation.lng = myMarkers[1]?.props?.position?.lng;

  console.log("start_location", startLocation);

  // const geocoder = new google.maps.Geocoder();
  // var startlatlng = new google.maps.LatLng(Number(startLocation.lat), Number(startLocation.lng));
  // geocoder.geocode({ location: startlatlng }, (results, status) => {
  //   console.log("start formated", results);

  // });
  // var endlatlng = new google.maps.LatLng(
  //   Number(endLocation.lat),
  //   Number(endLocation.lng)
  // );
  // geocoder.geocode({ location: endlatlng }, (results, status) => {
  //   console.log("end formated", results);
  // });

  return (
    <>
      <div style={{ height: "340px" , paddingTop:"50px", marginBottom:"30px" }}>
        <div style={{position:"absolute", top:"-16px" , width:"100%", zIndex:"200"}}>
          <EditMapInputForm />
        </div>

        <Map
          google={props.google}
          // @ts-ignore
          zoom={15}
          initialCenter={
            show === 0
              ? {
                  lat: picklatlong?.lat ? picklatlong?.lat : 24.7136,
                  lng: picklatlong?.lng ? picklatlong?.lng : 46.6753,
                }
              : {
                  lat: destinationlatlong?.lat
                    ? destinationlatlong?.lat
                    : endLat,
                  lng: destinationlatlong?.lng
                    ? destinationlatlong?.lng
                    : endlng,
                }
          }>
          {myMarkers}
        </Map>
      </div>
    </>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyDnO5gdUDI1we0x9noUejLSHlM4FKGPr9o",
})(GoogleMapComponent);
