import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import classReducer from "./slices/class";
import loaderReducer from "./slices/loader";
import bookingReducer from "./slices/booking";
import invoiceReducer from "./slices/invoice";
import customerReducer from "./slices/customer";
import formLoaderReducer from "./slices/formLoader";
import { mapReducer } from "./slices/map/mapSlice";
import { companyUserReducer } from "./slices/companyUser/companyUserSlice";

const appReducer = combineReducers({
  form: formReducer,

  auth: authReducer,
  modal: modalReducer,
  class: classReducer,
  loader: loaderReducer,
  booking: bookingReducer,
  invoice: invoiceReducer,
  customer: customerReducer,
  formLoader: formLoaderReducer,
  map: mapReducer,
  companyUser: companyUserReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
