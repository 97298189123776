import { Container } from "@mui/material";
import React from "react";
import "./Footer.scss";

import award from "assets/award.svg";
import youtube from "assets/youtube.svg";
import Linkedin from "assets/Linkedin.svg";
import Twitter from "assets/Twitter.svg";
import fb from "assets/fb.svg";



import email from "assets/email.png";
import appStore from "assets/appStore.png";
import playStore from "assets/playStore.png";



import Text from "components/atoms/Text";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";

const Footer = () => {

    const location = useLocation();
    const { pathname } = location;
    const user = useAppSelector((state) => state.auth.user);

    const { t } = useTranslation("translation");
	const {i18n} = useTranslation();

    	if (
        !user ||
        pathname === "/" ||
        pathname === "/password-otp" ||
        pathname === "/reset-password" ||
        pathname === "/forgot-password"
      )
        return <></>;
    return (
      <div className="footer__container">
        <Container maxWidth="lg">
          <div className="bestCar__Rental">
            <div className="left">
              <div className="award">
                <img src={award} alt="" className="award__img" />
              </div>
              <div className="awad__text">
                <Text
                  text={t("bestAward")}
                  size="16"
                  weight="700"
                  height="19"
                />
              </div>
            </div>
            <div className="right">
              <div className="first">
                <Text text={t("company")} size="16" weight="700" height="19" />
                <div className="links">
                  <Link to="/">{t("aboutUs")}</Link>
                  <Link to="/">{t("careers")}</Link>
                  <Link to="/">{t("partners")}</Link>
                  <Link to="/">{t("business")}</Link>
                </div>
              </div>
              <div className="first">
                <Text text={t('rent')} size="16" weight="700" height="19" />
                <div className="links">
                  <Link to="/">{t("fleet")}</Link>
                  <Link to="/">{t("locations")}</Link>
                </div>
              </div>
              <div className="first">
                <Text text={t("usefulLinks")} size="16" weight="700" height="19" />
                <div className="links">
                  <Link to="/">{t("contactUs")}</Link>
                  <Link to="/">{t("ourServices")}</Link>
                  <Link to="/">{t("howItWorks")}</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe__offer">
            <div className="subscribe">
              <Text
                text={t("subscribe")}
                size="16"
                weight="700"
                height="19"
              />
              <Text
                text={t("saveOurLatestPrice")}
                size="14"
                weight="400"
                height="17"
              />

           
<div 
    
    className={i18n.language ==="en"?"email":"email ar__email"}

    >
      <img src={email} alt="" />
      <input
      // @ts-ignore
        placeholder={t("email")}
        type="text"
        className="email__input"
      />
    </div>
            </div>
            <div className="subscribe">
              <Text text={t("yeloApp")} size="16" weight="700" height="19" />
              <Text
                text={t("bookAndManage")}
                size="14"
                weight="400"
                height="17"
              />
              {/* <div className="playstore">
                <img src={appStore} alt="" />
                <img src={playStore} alt="" />
              </div> */}

<div className="playStore">
                <a href='https://apps.apple.com/us/app/yelo-limousine/6446365805' target="_blank" rel="noreferrer">
                <img src={appStore} alt="" />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.wefaq.limousine' target="_blank" rel="noreferrer">
                <img src={playStore} alt="" />
                </a>
              </div>
            </div>
            <div className="subscribe">
              <Text text={t("connectWithUs")} size="16" weight="700" height="19" />
              <Text
                text={t("shareExperience")}
                size="14"
                weight="400"
                height="17"
              />
              <div className="social__links">
                <a target="_blank" href='https://www.facebook.com/YeloDrive/' rel="noreferrer">
                <img src={fb} alt="" />
                </a>
                <a target="_blank" href='https://twitter.com/YeloDrive' rel="noreferrer">
                <img src={Twitter} alt="" />
                </a>
                <a target="_blank" href='https://www.linkedin.com/company/yelodrive/

' rel="noreferrer">
                <img src={Linkedin} alt="" />


                </a>
                <a target="_blank" href='https://www.youtube.com/channel/UC-63qjJCB4LOw_4j8Nk5dmg' rel="noreferrer">
                <img src={youtube} alt="" />

                </a>
              

              </div>
            </div>
          </div>
          <div className="rights">
            <Text
              text={t("allRights")}
              size="14"
              weight="400"
              height="17"
            />
          </div>
        </Container>
      </div>
    );
};

export default Footer;
